<template>
  <div class="row">
    <b-modal :id="formId" :size="formWidth">
      <template #modal-title>
        <div v-if="formState == 'Update'">Update Services #{{ formData.id }}</div>
        <div v-if="formState == 'Create'">Create Services</div>
      </template>

      <EditServices v-if="formState == 'Update'" :key="formKey" :data="formData" :directionsData="directionsData"
                    :gridApi="formGridApi" :modalFormId="formId" @close="closeForm"/>


      <CreateServices v-if="formState == 'Create'" :key="formKey" :directionsData="directionsData"
                      :gridApi="formGridApi"
                      :modalFormId="formId" @close="closeForm"/>

      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>


    <div class="col-sm-12">
      <AgGridTable :key="tableKey" :cacheBlockSize="cacheBlockSize" :columnDefs="columnDefs"
                   :maxBlocksInCache="maxBlocksInCache"
                   :pagination="pagination" :paginationPageSize="paginationPageSize" :rowData="rowData"
                   :rowModelType="rowModelType"
                   :url="url" className="ag-theme-alpine" domLayout='autoHeight'
                   rowSelection="multiple" @gridReady="onGridReady">
        <template #header_buttons>
          <div v-if="!routeData.meta.hideCreate" class="btn btn-primary" @click="openCreate"><i
              class="fa fa-plus"></i> Ajouter un service
          </div>
        </template>

      </AgGridTable>

    </div>
  </div>
</template>


<script>

import moment from 'moment'


export default {
  name: 'ServicesView',
  components: {
    DataTable: () => import( "@/components/DataTable.vue"),
    AgGridTable: () => import("@/components/AgGridTable.vue"),
    CreateServices: () => import( "./CreateServices.vue"),
    EditServices: () => import( "./EditServices.vue"),
    DataModal: () => import("@/components/DataModal.vue"),
    CustomFiltre: () => import("@/components/CustomFiltre.vue"),
    AgGridBtnClicked: () => import("@/components/AgGridBtnClicked.vue")
  },
  data() {

    return {
      formId: "services",
      formState: "",
      formData: {},
      formWidth: 'lg',
      formGridApi: {},
      formKey: 0,
      tableKey: 0,
      url: 'http://127.0.0.1:8000/api/services-Aggrid1',
      table: 'services',
      directionsData: [],
      requette: 1,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      rowModelType: null,
      pagination: true,
      paginationPageSize: 100,
      cacheBlockSize: 10,
      maxBlocksInCache: 1,
    }
  },

  computed: {
    routeData: function () {
      let router = {meta: {}}
      if (window.router) {
        try {
          router = window.router;
        } catch (e) {
        }
      }


      return router
    },
    taille: function () {
      let result = 'col-sm-12'
      if (this.filtre) {
        result = 'col-sm-9'
      }
      return result
    },
  },
  watch: {
    'routeData': {
      handler: function (after, before) {
        this.gridApi.setFilterModel(null)
        this.gridApi.refreshServerSide()
      },
      deep: true
    },
  },
  created() {
    this.url = this.axios.defaults.baseURL + '/api/services-Aggrid1',
        this.formId = this.table + "_" + Date.now()
    this.rowBuffer = 0;
    this.rowModelType = 'serverSide';
    this.cacheBlockSize = 50;
    this.maxBlocksInCache = 2;

  },
  beforeMount() {
    this.columnDefs =
        [

          {
            field: "services.id",
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true,},
            hide: true,
          },
          {
            field: null,
            headerName: '',
            suppressCellSelection: true,
            width: 40,
            pinned: 'left',
            cellRendererSelector: params => {
              return {
                component: 'AgGridBtnClicked',
                params: {
                  clicked: field => {
                    this.showForm('Update', field, params.api)
                  },
                  render: `<div class="btn btn-primary  d-flex justify-content-center align-items-center" style="width:100%;height:100%;color:#fff;border-radius:5px;text-align:center;cursor:pointer">  <i class="fa-solid fa-pen-to-square "></i></div>`
                }
              };
            },

          },


          {
            field: "services.code",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'code',
          },


          {
            field: "services.libelle",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'libelle',
          },


          {
            headerName: 'direction',
            field: 'directions.libelle',
            join: {
              table: 'directions',
              champ1: 'services.direction_id',
              champ2: 'directions.id',
              operateur: "="
            }
          },

          {
            field: "services.created_at",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'Créer le',
            valueFormatter: params => {
              let retour = params.value
              try {
                retour = moment(params.value).format('DD/MM/YYYY à HH:mm')
              } catch (e) {

              }
              return retour
            }
          },

        ];


  },
  mounted() {
    if (this.requette > 0) {
      // this.$store.commit('setIsLoading', true)
    }

    // this.getdirections();

  },
  methods: {
    closeForm() {
      try {
        this.gridApi.refreshServerSide()
      } catch (e) {

      }
    },
    openCreate() {
      this.showForm('Create', {}, this.gridApi)
    },
    showForm(type, data, gridApi, width = 'lg') {
      this.formKey++
      this.formWidth = width
      this.formState = type
      this.formData = data
      this.formGridApi = gridApi
      this.$bvModal.show(this.formId)
    },
    onGridReady(params) {
      console.log('on demarre', params)
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.isLoading = false
      this.gridApi.sizeColumnsToFit();

    },
    getdirections() {
      this.axios.get('/api/directions').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.directionsData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

  }
}
</script>
